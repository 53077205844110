var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"xl":"6"}},[_c('b-card',[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.t('Login Details'))+" ")]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Select Services'),"label-for":"vi-assign-services"}},[_c('validation-provider',{attrs:{"name":"Select Services","vid":"vi-assign-services","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.services,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"Select Services","label":"service_name","track-by":"service_name"},on:{"select":_vm.handleForm},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('span',{staticClass:"custom__tag mul-sel-tag"},[_c('span',[_vm._v(_vm._s(option.service_name))]),_c('span',{staticClass:"custom__remove cursor-pointer",on:{"click":function($event){return remove(option)}}},[_vm._v("❌")])])]}}],null,true),model:{value:(_vm.form.services),callback:function ($$v) {_vm.$set(_vm.form, "services", $$v)},expression:"form.services"}},[_c('template',{slot:"beforeList"},[_c('b-form-checkbox',{staticClass:"col-md-6 m-1",on:{"input":function($event){_vm.selectAll ? _vm.form.services = _vm.services : _vm.form.services = []}},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}},[_vm._v(" Select All Services ")])],1)],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Username'),"label-for":"vi-user-name"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"vi-user-name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-user-name","autocomplete":"off","placeholder":_vm.t('Username'),"maxlength":"50"},on:{"change":_vm.handleForm},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Employee Code'),"label-for":"vi-employee-code"}},[_c('validation-provider',{attrs:{"name":"Employee Code","vid":"vi-employee-code","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-employee-code","autocomplete":"off","placeholder":_vm.t('Employee Code'),"maxlength":"50"},on:{"change":_vm.handleForm},model:{value:(_vm.form.orange_hrms_code),callback:function ($$v) {_vm.$set(_vm.form, "orange_hrms_code", $$v)},expression:"form.orange_hrms_code"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('b-row',{staticClass:"px-1"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{class:_vm.req ? 'required' : '',attrs:{"label":_vm.t('OTP Login'),"label-for":"vi-otp-login"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"check-button","switch":"","inline":""},on:{"change":_vm.handleForm},model:{value:(_vm.form.otp_login),callback:function ($$v) {_vm.$set(_vm.form, "otp_login", $$v)},expression:"form.otp_login"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.t('Face Login'),"label-for":"vi-face-login"}},[_c('b-form-checkbox',{attrs:{"checked":"true","name":"face-login","switch":"","inline":""},on:{"change":_vm.handleFormFace},model:{value:(_vm.form.face_login),callback:function ($$v) {_vm.$set(_vm.form, "face_login", $$v)},expression:"form.face_login"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{class:_vm.req ? 'required' : '',attrs:{"label":_vm.t('Password'),"label-for":"vi-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"vi-password","rules":_vm.req ? 'required|password' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-password","type":_vm.passwordFieldType,"autocomplete":"off","placeholder":_vm.t('Password')},on:{"change":_vm.handleForm},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{class:_vm.req ? 'required' : '',attrs:{"label":_vm.t('Confirm Password'),"label-for":"vi-confirm-password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","vid":"vi-confirm-password","rules":_vm.req ? 'required|confirmed:vi-password' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-confirm-password","type":_vm.passwordFieldType,"autocomplete":"off","placeholder":_vm.t('Confirm Password')},on:{"change":_vm.handleForm},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('User Type'),"label-for":"vi-user-type"}},[_c('validation-provider',{attrs:{"name":"User Type","vid":"vi-user-type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"group_desc","placeholder":_vm.t('User Type'),"reduce":function (ut) { return ut.group_id; },"options":_vm.userTypes},on:{"input":_vm.handleForm},model:{value:(_vm.form.user_type),callback:function ($$v) {_vm.$set(_vm.form, "user_type", $$v)},expression:"form.user_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Reporting To'),"label-for":"vi-assign-to"}},[_c('validation-provider',{attrs:{"name":"Select User","vid":"vi-assign-to","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"company_user_name","placeholder":_vm.t('Select User'),"reduce":function (ut) { return ut.companyuserid; },"options":_vm.users},on:{"input":_vm.handleForm},model:{value:(_vm.form.assignTo),callback:function ($$v) {_vm.$set(_vm.form, "assignTo", $$v)},expression:"form.assignTo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.t('Department'),"label-for":"vi-department"}},[_c('validation-provider',{attrs:{"name":"Department","vid":"vi-department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"p-0 border-0",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"department_name","placeholder":_vm.t('Department'),"reduce":function (ut) { return ut.id; },"options":_vm.departments},on:{"input":_vm.handleForm},model:{value:(_vm.form.department),callback:function ($$v) {_vm.$set(_vm.form, "department", $$v)},expression:"form.department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{class:_vm.pic_required,attrs:{"label":_vm.t('Profile Photo'),"label-for":"vi-profile-photo"}},[(_vm.form.profile_pic_url)?_c('div',{attrs:{"id":"profile_photo_preview"}},[_c('img',{attrs:{"src":_vm.form.profile_pic_url,"max-height":"220","alt":""}})]):_vm._e(),_c('validation-provider',{attrs:{"name":"Profile Photo","vid":"vi-profile-photo","rules":_vm.pic_required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-file',{attrs:{"id":"vi-profile-photo","accept":"image/*","type":"file","placeholder":_vm.t('Upload Profile Photo'),"browse-text":_vm.t('Browse')},on:{"change":_vm.getprofile},model:{value:(_vm.form.profile_photo),callback:function ($$v) {_vm.$set(_vm.form, "profile_photo", $$v)},expression:"form.profile_photo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.t(errors[0])))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }