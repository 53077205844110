<template>
  <b-col xl="6">
    <b-card>
      <b-card-title class="mb-1">
        {{ t('Login Details') }}
      </b-card-title>
      <b-row>
        <!-- Select Services -->
        <b-col cols="12">
          <b-form-group
            :label="t('Select Services')"
            label-for="vi-assign-services"
            class="required"
          >
            <validation-provider
              #default="{ errors }"
              name="Select Services"
              vid="vi-assign-services"
              rules="required"
            >

              <multiselect
                v-model="form.services"
                :options="services"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Select Services"
                label="service_name"
                track-by="service_name"
                @select="handleForm"
              >
                <template
                  slot="beforeList"
                >
                  <b-form-checkbox
                    v-model="selectAll"
                    class="col-md-6 m-1"
                    @input="selectAll ? form.services = services : form.services = []"
                  >
                    Select All Services
                  </b-form-checkbox>
                </template>
                <template
                  slot="tag"
                  slot-scope="{ option, remove }"
                >
                  <span class="custom__tag mul-sel-tag"><span>{{ option.service_name }}</span><span
                    class="custom__remove cursor-pointer"
                    @click="remove(option)"
                  >❌</span></span>
                </template>
              </multiselect>
              <!-- <v-select
                v-model="form.assigned_service"
                class="p-0 border-0"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="service_name"
                :placeholder="t('Select Services')"
                multiple
                :reduce="service => service.id"
                :options="services"
                @input="handleForm"
              /> -->
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Username -->
        <b-col cols="6">
          <b-form-group
            :label="t('Username')"
            label-for="vi-user-name"
            class="required"
          >
            <validation-provider
              #default="{ errors }"
              name="Username"
              vid="vi-user-name"
              rules="required|min:3"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-user-name"
                  v-model="form.username"
                  autocomplete="off"
                  :placeholder="t('Username')"
                  maxlength="50"
                  @change="handleForm"
                />
              </b-input-group>
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Employee Code -->
        <b-col cols="6">
          <b-form-group
            :label="t('Employee Code')"
            label-for="vi-employee-code"
            class="required"
          >
            <validation-provider
              #default="{ errors }"
              name="Employee Code"
              vid="vi-employee-code"
              rules="required|min:3"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="UserIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="vi-employee-code"
                  v-model="form.orange_hrms_code"
                  autocomplete="off"
                  :placeholder="t('Employee Code')"
                  maxlength="50"
                  @change="handleForm"
                />
              </b-input-group>
              <small class="text-danger">{{ t(errors[0]) }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Status -->
        <b-col
          cols="6"
          class="p-0"
        >

          <b-row class="px-1">
            <!-- OTP Login -->
            <b-col cols="6">
              <b-form-group
                :label="t('OTP Login')"
                label-for="vi-otp-login"
                :class="req ? 'required' : ''"
              >
                <b-form-checkbox
                  v-model="form.otp_login"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                  @change="handleForm"
                />
              </b-form-group>
            </b-col>
            <!-- face Login -->
            <b-col cols="6">
              <b-form-group
                :label="t('Face Login')"
                label-for="vi-face-login"
              >
                <b-form-checkbox
                  v-model="form.face_login"
                  checked="true"
                  name="face-login"
                  switch
                  inline
                  @change="handleFormFace"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-col cols="12">
            <b-form-group
              :label="t('Password')"
              label-for="vi-password"
              :class="req ? 'required' : ''"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="vi-password"
                :rules="req ? 'required|password' : ''"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-password"
                    v-model="form.password"
                    :type="passwordFieldType"
                    autocomplete="off"
                    :placeholder="t('Password')"
                    @change="handleForm"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="t('Confirm Password')"
              label-for="vi-confirm-password"
              :class="req ? 'required' : ''"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                vid="vi-confirm-password"
                :rules="req ? 'required|confirmed:vi-password' : ''"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LockIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="vi-confirm-password"
                    v-model="form.confirmPassword"
                    :type="passwordFieldType"
                    autocomplete="off"
                    :placeholder="t('Confirm Password')"
                    @change="handleForm"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="t('User Type')"
              label-for="vi-user-type"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="User Type"
                vid="vi-user-type"
                rules="required"
              >
                <v-select
                  v-model="form.user_type"
                  class="p-0 border-0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="group_desc"
                  :placeholder="t('User Type')"
                  :reduce="ut => ut.group_id"
                  :options="userTypes"
                  @input="handleForm"
                />
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="t('Reporting To')"
              label-for="vi-assign-to"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="Select User"
                vid="vi-assign-to"
                rules="required"
              >
                <v-select
                  v-model="form.assignTo"
                  class="p-0 border-0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="company_user_name"
                  :placeholder="t('Select User')"
                  :reduce="ut => ut.companyuserid"
                  :options="users"
                  @input="handleForm"
                />
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="t('Department')"
              label-for="vi-department"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="Department"
                vid="vi-department"
                rules="required"
              >
                <v-select
                  v-model="form.department"
                  class="p-0 border-0"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="department_name"
                  :placeholder="t('Department')"
                  :reduce="ut => ut.id"
                  :options="departments"
                  @input="handleForm"
                />
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-col>

        <b-col
          cols="6"
          class="p-0"
        >
          <b-col cols="12">
            <b-form-group
              :label="t('Profile Photo')"
              label-for="vi-profile-photo"
              :class="pic_required"
            >
              <div
                v-if="form.profile_pic_url"
                id="profile_photo_preview"
              >
                <img
                  :src="form.profile_pic_url"
                  max-height="220"
                  alt=""
                >
              </div>
              <validation-provider
                #default="{ errors }"
                name="Profile Photo"
                vid="vi-profile-photo"
                :rules="pic_required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-file
                    id="vi-profile-photo"
                    v-model="form.profile_photo"
                    accept="image/*"
                    type="file"
                    :placeholder="t('Upload Profile Photo')"
                    :browse-text="t('Browse')"
                    @change="getprofile"
                  />
                </b-input-group>
                <small class="text-danger">{{ t(errors[0]) }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { ValidationProvider } from 'vee-validate'
import 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, password } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { useUtils } from '@core/libs/i18n'
import store from '@/store'

// import LoginDetails from './CreateUserForm/LoginDetails.vue'

export default {
  name: 'LoginDetails',
  components: {
    vSelect,
    Multiselect,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: ['req', 'editdata'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      required,
      password,
      selectAll: false,
      pic_required: '',
      form: {
        assigned: [],
        department: '',
        face_login: '',
        otp_login: '',
        profile_photo: [],
        assigned_service: [],
        profile_pic_url: 'https://staging-lr.yugtia.com/assets/images/users/1.jpg',
        assignTo: '',
      },
      user_type: '',
      userTypes: [],
      users: [],
      departments: [],
      services: [],
      serve: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    // pic_required() {
    //   return this.form.face_login ? 'required' : ''
    // },
  },
  watch: {
    editdata(newVal) {
      this.form = newVal
      // eslint-disable-next-line eqeqeq
      this.form.face_login = this.editdata.face_login_on == 1
      this.pic_required = this.form.face_login ? 'required' : ''
      const c = parseInt(newVal.user_type, 10)
      this.form.user_type = c
      const u = parseInt(newVal.reporting_to, 10)
      this.form.assignTo = u
      this.form.department = parseInt(newVal.department_id, 10)
      this.form.services.forEach((element, i) => {
        this.serve[i] = element.id
      })
      this.form.assigned_service = this.serve
      // eslint-disable-next-line eqeqeq
      if (this.form.otp_login_on == 1) { this.form.otp_login = true }
      // eslint-disable-next-line eqeqeq
      // if (this.form.face_login_on == 1) { this.form.face_login = true }
      if (this.form.profile_pic_url === '') { this.form.profile_pic_url = 'https://staging-lr.yugtia.com/assets/images/users/1.jpg' }
    },
  },
  mounted() {
    store.dispatch('CompanyuserManage/getUserType').then(response => {
      if (response.data.code === '200') {
        this.userTypes = response.data.data
      }
    })
    store.dispatch('CompanyuserManage/getAutoUsers').then(response => {
      if (response.data.code === '200') {
        this.users = response.data.data
      }
    })
    store.dispatch('CompanyuserManage/getServices').then(response => {
      if (response.data.code === '200') {
        this.services = response.data.data
      }
    })
    store.dispatch('ManageLeads/getDepartments').then(response => {
      if (response.data.code === '200') {
        this.departments = response.data.data
      }
    })
  },
  methods: {
    handleForm() {
      this.$emit('getCreateUser', this.form)
    },
    handleFormFace() {
      this.pic_required = this.form.face_login ? 'required' : ''
      this.$emit('getCreateUser', this.form)
    },
    getprofile(event) {
      if (event.target.files.length > 0) this.form.profile_pic_url = URL.createObjectURL(event.target.files[0])
      else this.form.profile_pic_url = 'https://staging-lr.yugtia.com/assets/images/users/1.jpg'
      this.$emit('getCreateUser', event.target.files[0], 'images')
    },
  },
}
</script>

<style>
.multiselect__content-wrapper::-webkit-scrollbar {
  width: 10px;
}
.multiselect__content-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.3);
}
.multiselect__content-wrapper::-webkit-scrollbar-thumb{
  background-color: #7267f08a !important;
  outline: 1px solid #7367f0 !important;
  border-radius: 10px;
}
.mul-sel-tag {
  margin: 0px 5px 5px 5px;
  color: white;
  display: inline-block;
  background: #7367f0 !important;
  border-radius: 3px;
  padding: 2px 5px 2px 5px;
}
</style>
