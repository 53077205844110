<template>
  <validation-observer
    ref="createUser"
  >
    <b-form
      autocomplete="off"

      @submit.prevent="createUser"
    >
      <b-row>
        <login-details
          :req="req"
          :editdata="form"
          @getCreateUser="getFor"
        />

        <user-details
          :req="req"
          :editdata="form"
          @getCreateUser="getFor"
        />
        <!-- reset and submit -->
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            {{ t('Submit') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="redirectToCompanyUserList"
          >
            {{ t('Cancel') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCol, BForm, BRow, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils } from '@core/libs/i18n'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LoginDetails from './UserFormComponents/LoginDetails.vue'
import UserDetails from './UserFormComponents/UserDetails.vue'
// import ReportTo from './UserFormComponents/ReportTo.vue'
import store from '@/store'
import { hideLoader, showLoader } from '@/utils/common'

export default {
  name: 'CreateUser',
  components: {
    BRow,
    BButton,
    BCol,
    BForm,
    LoginDetails,
    UserDetails,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      req: true,
      form: {
        assigned: [],
        assigned_service: '',
        username: '',
        orange_hrms_code: '',
        password: '',
        confirmPassword: '',
        email: '',
        user_type: '',
        mobile: '',
        status: '',
        address1: '',
        address2: '',
        firstname: '',
        lastname: '',
        city: '',
        city_name: '',
        zip: '',
        state: '',

      },
      profile_photo: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  mounted() {
    if (this.$route.params.id) {
      document.getElementById('loading-bg').classList.add('loading-bg')

      this.form.companyuserid = this.$route.params.id
      this.req = false
      store.dispatch('CompanyuserManage/getUserById', { companyuserid: this.form.companyuserid })
        .then(response => {
          if (response.data.code === '200') {
            this.form = response.data.data
            this.form.companyuserid = this.$route.params.id
          }
          document.getElementById('loading-bg').classList.remove('loading-bg')
        }).catch(e => {
          // eslint-disable-next-line eqeqeq
          if (e.msg == 'Unauthenticated.') {
            this.$router.back()
            document.getElementById('loading-bg').classList.remove('loading-bg')
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'No Permission',
                icon: 'Danger',
                variant: 'danger',
                text: '',
              },
            })
          }
        })
    } else {
      this.form.companyuserid = '0'
    }
  },
  created() {
    // this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  methods: {
    getFor(value, name, type, remove) {
      if (!name) {
        Object.assign(this.form, value)
      } else if (type === 'checkbox') {
        if (value) {
          this.form[name].push(value)
        } else {
          this.form[name].splice(this.form[name].indexOf(remove), 1)
        }
      } else if (name === 'images') {
        this.profile_photo = value
      } else {
        this.form[name] = value
      }
    },
    redirectToCompanyUserList() {
      this.$router.push('/company-users/list')
    },
    createUser() {
      this.$refs.createUser.validate().then(success => {
        if (success) {
          this.form.assigned_service = []
          this.form.services.forEach((ser, i) => {
            this.form.assigned_service[i] = ser.id
          })
          // eslint-disable-next-line prefer-const
          let formData = new FormData()

          formData.append('profile_photo', this.profile_photo)
          // eslint-disable-next-line prefer-const
          let FormsDatas = this.form
          Object.entries(FormsDatas).forEach(
            ([key, value]) => { if (key !== 'profile_photo') formData.append(key, value) },
          )
          // eslint-disable-next-line eqeqeq
          if (FormsDatas.companyuserid == '0') {
            this.addUser(formData)
          } else {
            this.updateUser(formData)
          }
        }
      })
    },
    async addUser(formData) {
      showLoader()
      await store.dispatch('CompanyuserManage/createUser', formData).then(response => {
        if (response.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.msg}`,
              icon: 'Success',
              variant: 'success',
              text: '',
            },
          })
          hideLoader()
          this.$router.push('/company-users/list')
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.msg}`,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${e.message}`,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
    async updateUser(formData) {
      showLoader()
      await store.dispatch('CompanyuserManage/updateUser', formData).then(response => {
        if (response.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.msg}`,
              icon: 'Success',
              variant: 'success',
              text: '',
            },
          })
          hideLoader()
          this.$router.push('/company-users/list')
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.msg}`,
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
          hideLoader()
        }
      }).catch(e => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${e.message}`,
            icon: 'Danger',
            variant: 'danger',
            text: '',
          },
        })
        hideLoader()
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.card-body {
  overflow: none;
}
#profile_photo_preview{
    text-align: center;
}
#profile_photo_preview img{
    padding: 10px;
    max-height: 164px;
    width: auto;
}
@media (max-width: 400px){
  #profile_photo_preview img{
    width: 100%;
}
}
</style>
